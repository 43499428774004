<template>
  <div class="dashboard-widget-container subscribe-banner-widget">
    <img @click="closeSubscribeWidget()" src="@/assets/img/icons/close_btn_icon.svg" alt="">
    <div class="subscribe-banner-widget-wrap">
      <img src="@/assets/img/icons/email_icon.svg" alt="">
      <h3>Subscribe Now!</h3>
      <p class="subs_text">Subscribe to our newsletter to stay updated on exciting news & offers!</p>
      <button class="basic_green-btn" @click="subscribeToNews()">Subscribe</button>

      <p style="color: red; word-break: break-all;" v-if="validationErrors.message">{{validationErrors.message}}</p>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex"
import {CLOSE_SUBSCRIBE_WIDGET, SUBSCRIBE_USER_TO_NEWS} from "@/store/actions"

export default {
  name: "SubscribeBannerWidget",
  props:[
    'widgetData'
  ],
  computed: {
    ...mapGetters([
      'validationErrors',
    ])
  },
  methods:{
    async subscribeToNews(){
      await this.$store.dispatch(SUBSCRIBE_USER_TO_NEWS,{
        address: this.$auth.user().email,
      }).then(() => {
        if(!this.validationErrors.message){
          this.$emit('closeSubsForm')
        }
      }).finally(() => {
        //
      })
    },
    async closeSubscribeWidget(){
      await this.$store.dispatch(CLOSE_SUBSCRIBE_WIDGET,{
        id: this.widgetData.id,
        params: {
          options: { hide: true }
        }
      }).then(() => {

      }).finally(() => {
        this.$emit('closeSubsForm')
      })
    }
  }
}
</script>
