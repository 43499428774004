<template>
  <ul>
    <li>
      <router-link :to="{ name: 'client.dashboard.invest' }">
        Invest more +
      </router-link>
    </li>
    <li @click="cloneSelectedPortfolio(depositItem)">
      Clone portfolio
    </li>
    <li v-if="depositItem && depositItem.portfolio && depositItem.portfolio.wp_post_id && depositItem.portfolio.slug">
      <a :href="generateWpPortfolioLink()" :target="'_blank'">
        {{ `About ${depositItem.portfolio.slug}` }}
      </a>
    </li>
  </ul>
</template>

<script>
import {mapGetters} from "vuex"

export default {
  name: "DepositsWidgetDropdown",
  props: ['depositItem'],
  computed: {
    ...mapGetters([
      //
    ])
  },
  methods: {
    generateWpPortfolioLink(){
      return `${process.env.VUE_APP_WP_URL}/?page_id=${this.depositItem.portfolio.wp_post_id}`
    },
    cloneSelectedPortfolio(depositItem){
      this.$router.push({ name: 'client.dashboard.invest', params: {
        portfolio: depositItem.portfolio,
        amount: depositItem.amount_in_currency,
        compounding: depositItem.compound_rate,
      } })
    },
  }
}
</script>
