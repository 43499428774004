<template>
  <div class="dashboard-widget-container twitter-timeline-widget">
    <div class="twitter-timeline-widget-header">
      <h4>
        <img :src="require('@/assets/img/icons/twitter-green.svg')" alt="Twitter"/>
        <span>Recent Tweets</span>
      </h4>

      <a :href="`https://twitter.com/intent/follow?screen_name=${twitterUserName}`" target="_blank">
        Follow
      </a>
    </div>

    <div class="twitter-timeline-widget-content">
      <div id="twitter-timeline-holder">
        <p class="loader"></p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TwitterTimelineWidget",
  props:[
    'widgetData'
  ],
  data() {
    return {
      twitterUserName: 'EinvestmentCom',
    }
  },
  mounted() {
    this.loadTwitterWidgets()
  },
  methods:{
    async loadTwitterWidgets(){
      /*
       * Link: https://dev.twitter.com/web/embedded-timelines/parameters
       * Link: https://developer.twitter.com/en/docs/twitter-for-websites/timelines/guides/parameter-reference
       * Link: https://medium.com/@socrates3142/how-to-embed-tweets-on-vue-2-vue-3-622995b6175
       */
      let twitterUserName = this.twitterUserName

      window.TwitterWidgetsLoader.load(function(err, twttr) {
        if (err) {
          //do some graceful degradation / fallback
          return
        }

        /*
         *
         */
        let dataSource = {
          sourceType: 'profile',
          screenName: twitterUserName,
        }

        let element = document.getElementById('twitter-timeline-holder')
        let target = element.parentElement

        let options = {
          lang: 'en',
          chrome: 'noheader,nofooter,noborders',
        }

        twttr.ready(function(twttr) {
          element.remove()
          twttr.widgets.createTimeline(dataSource, target, options)
        })
      })
    },
  }
}
</script>
