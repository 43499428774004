<template>
  <div class="dashboard-widget-container available-funds-widget">
    <div class="available-funds-widget-head">
      <h3 v-if="widgetData && widgetData.title">{{widgetData.title}}</h3>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <span class="help_tip_btn" v-bind="attrs" v-on="on">?</span>
        </template>
        <span>Funds available for withdrawal or re-investment</span>
      </v-tooltip>
    </div>

    <div class="available-funds-widget-content">
      <p v-if="widgetData && widgetData.widget_data" v-html="formatEuroCurrencySymbol(widgetData.widget_data)"></p>

      <router-link :to="{ name: 'client.dashboard.withdraw' }">
        Withdrawal
      </router-link>
    </div>
  </div>
</template>

<script>
import currencySymbolFormatter from "@/mixins/currencySymbolFormatter"

export default {
  name: "AvailableFundsWidget",
  props:['widgetData'],
  mixins:[
    currencySymbolFormatter,
  ]
}
</script>
