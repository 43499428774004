<template>
  <div class="dashboard-widget-container info-banner-widget">
    <div :style="{ 'background-image': `url(${prepareBannerSrc(widgetData.widget_data.img)})` }" class="info-banner-widget-image"></div>
<!--    <img :src="prepareBannerSrc(widgetData.widget_data.img)" alt="">-->
  </div>
</template>

<script>
export default {
  name: "InfoBannerWidget",
  props:[
    'widgetData'
  ],
  methods:{
    prepareBannerSrc(rawSrc) {
      return process.env.VUE_APP_API_URL + rawSrc
    },
  }
}
</script>
