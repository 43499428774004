<template>
  <div class="dashboard-widget-container transaction-history-and-upcoming-widget">
    <div class="transaction-history-and-upcoming-widget-tabs">
      <tabs :options="{ useUrlFragment: false }" @changed="tabChanged">
        <tab name="Transactions History" id="transactionsHistory">
          <div class="transaction-history-table scroll-container">
            <template v-if="Object.values(widgetData.widget_data.history.data).length > 0">
              <div
                class="transaction-history-table-date-row"
                v-for="(historyItems, historyDate) in widgetData.widget_data.history.data"
                :key="historyDate">

                <span>{{historyDate}}</span>
                <div
                  class="transaction-history-table-wrap"
                  v-for="historyItem in historyItems"
                  :key="historyItem.transaction_id">

                  <div class="transaction-history-table-line transaction-history-table-icon">
                    <img :src="checkStatementTypeIcon(historyItem.transaction_type)" alt="">
                  </div>
                  <div class="transaction-history-table-line transaction-history-table-description">
                    <span class="descr">{{isEmptyField(historyItem, 'description')}}</span>
                    <span class="date">{{ timestampToFormattedDate(historyItem.created_at, false, '—') }}</span>
                  </div>
                  <div class="transaction-history-table-line transaction-history-table-type">
                  <span :class="{fee: historyItem.transaction_type === 'performance_fee', earned: historyItem.transaction_type === 'interest_earned'}">
                    {{isEmptyField(historyItem, 'formatted_type')}}
                  </span>
                  </div>
                  <div class="transaction-history-table-line transaction-history-table-pdf">
                    <button
                      v-if="historyItem.transaction_type === 'deposit' || historyItem.transaction_type === 'withdrawal'"
                      @click="exportStatementItem(historyItem.id)">
                      <img src="@/assets/img/icons/clip.svg" alt="">
                    </button>
                  </div>
                  <div class="transaction-history-table-line transaction-history-table-amount">
                  <span
                    :class="{fee: historyItem.transaction_type === 'performance_fee', earned: historyItem.transaction_type === 'interest_earned'}"
                    v-html="formatEuroCurrencySymbol(historyItem.formatted_amount)"></span>
                  </div>

                  <!--                <div class="transaction-history-table-line transaction-history-table-more">-->
                  <!--                  <button @click="showHistoryInfo(historyItem)">-->
                  <!--                    <img src="@/assets/img/icons/transaction_more_btn.svg" alt="">-->
                  <!--                  </button>-->
                  <!--                </div>-->
                </div>
              </div>
            </template>
            <template v-else>
              <div class="no-result-container">
                <p>TRANSACTIONS HISTORY IS EMPTY</p>
              </div>
            </template>

<!--            <HistoryInfoPopUp v-if="showHistoryInfoItem" @closePopUp="closeHistoryInfoItemPopUp()" :historyInfoItem="historyInfoItem"/>-->

            <div class="transaction-history-table_more">
              <router-link v-if="widgetData.widget_data.history.meta.show_load_more" class="basic_green-btn" target="_blank" :to="{name:'client.dashboard.statement'}">
                Show More
              </router-link>
            </div>
          </div>
        </tab>

        <tab name="Upcoming" id="upcoming">
          <div class="transaction-history-table scroll-container">
            <div
              class="transaction-history-table-date-row"
              v-for="(upcomingItems, upcomingDate) in widgetData.widget_data.upcoming"
              :key="upcomingDate">

              <span>{{upcomingDate}}</span>
              <div
                class="transaction-history-table-wrap"
                v-for="upcomingItem in upcomingItems"
                :key="upcomingItem.id">

                <div class="transaction-history-table-line transaction-history-table-icon">
                  <img :src="checkStatementTypeIcon(upcomingItem.transaction_type)" alt="">
                </div>
                <div class="transaction-history-table-line transaction-history-table-name">
                  <span class="descr">{{isEmptyField(upcomingItem, 'formatted_id')}}</span>
                  <span class="date">{{ timestampToFormattedDate(upcomingItem.next_deposit_interest_date, false, '—') }}</span>
                </div>
                <div class="transaction-history-table-line transaction-history-table-type">
                  <span :class="{fee: upcomingItem.transaction_type === 'performance_fee', earned: upcomingItem.transaction_type === 'interest_earned'}">
                    {{isEmptyField(upcomingItem, 'formatted_type')}}
                  </span>
                </div>
                <div class="transaction-history-table-line transaction-history-table-progress">
<!--                  <span class="testspan">+ €********</span>-->
                  <span>+ € {{ progressText }}</span>
                </div>
                <div class="transaction-history-table-line transaction-history-table-status">
                  <span>In progress</span>
                </div>
              </div>
            </div>

          </div>
        </tab>
      </tabs>
    </div>
  </div>
</template>

<script>
import { Tabs, Tab } from 'vue-tabs-component'
import checkStatementTypeIcon from "@/mixins/checkStatementTypeIcon"
import isEmptyField from "@/mixins/isEmptyField"
import timeStampAndDateTransform from "@/mixins/timeStampAndDateTransform"
import currencySymbolFormatter from "@/mixins/currencySymbolFormatter"
import {
  EXPORT_STATEMENT_ITEM
} from "@/store/actions"
// import HistoryInfoPopUp from "@/components/basic/popups/HistoryInfoPopUp"
// import DropdownList from "@/components/basic/DropdownList"

export default {
  name: "TransactionHistoryAndUpcomingWidget",
  props:[
    'widgetData'
  ],
  components:{
    // HistoryInfoPopUp,
    // DropdownList,
    Tabs,
    Tab
  },
  mixins: [
    checkStatementTypeIcon,
    isEmptyField,
    timeStampAndDateTransform,
    currencySymbolFormatter,
  ],
  data(){
    return{
      historyInfoItem: null,
      showHistoryInfoItem: false,

      text: '********',
      progressText: '',
      currentIndex: 0,
      intervalId: null,
    }
  },
  beforeDestroy() {
    clearInterval(this.intervalId)
  },
  methods:{
    tabChanged (selectedTab) {
      if (selectedTab.tab.id === 'transactionsHistory') {
        clearInterval(this.intervalId)
      }
      if (selectedTab.tab.id === 'upcoming'){
        this.intervalId = setInterval(() => {
          if (this.currentIndex < this.text.length) {
            this.progressText += this.text.charAt(this.currentIndex)
            this.currentIndex++
          } else {
            this.currentIndex = 0
            this.progressText = ""
          }
        }, 400)
      }
    },
    exportStatementItem(id){
      return this.$store.dispatch(EXPORT_STATEMENT_ITEM, { id: id })
    },
    showHistoryInfo(item){
      this.historyInfoItem = item
      this.showHistoryInfoItem = true
    },
    closeHistoryInfoItemPopUp(){
      this.historyInfoItem = null
      this.showHistoryInfoItem = false
    }
  }
}
</script>
