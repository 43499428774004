<template>
  <div class="dashboard-widget-container deposits-list-widget">
    <div class="deposits-list-widget-container">
      <div class="deposits-list-widget-col deposits-list-widget-content-col">
        <div class="dashboard-deposit-list">
          <div v-if="widgetData && widgetData.widget_data" class="dashboard-deposit-list-items-wrap">
            <div
              class="dashboard-deposit-list-items"
              v-for="depositItem in widgetData.widget_data"
              :key="depositItem.id">

              <div class="dashboard-deposit-item-row dashboard-deposit-item-title-row">
                <div class="dashboard-deposit-name">
                  <h4 :title="isEmptyField(depositItem, 'formatted_id')">{{isEmptyField(depositItem, 'formatted_id')}}</h4>
                </div>

                <div class="dashboard-deposit-last-interest">
                  <template v-if="depositItem.last_deposit_interest && depositItem.last_deposit_interest.type !== 'nullable'">
                    <img :src="getDepositInterestType(depositItem.last_deposit_interest.type)" alt="deposit-interest-icon"/>
                    <span
                      v-if="depositItem.last_deposit_interest"
                      :class="`dashboard-deposit-inception dashboard-deposit-inception-${depositItem.last_deposit_interest.type}`">
                    {{ `${depositItem.last_deposit_interest.deposit_interest_rate.toFixed(2)}%` }}
                  </span>
                  </template>
                </div>

                <div class="dashboard-deposit-dropdown">
                  <DropdownList>
                    <DepositsWidgetDropdown :depositItem="depositItem"/>
                  </DropdownList>
                </div>
              </div>

              <div class="dashboard-deposit-item-row dashboard-deposit-item-descr-row">
                <p v-if="depositItem.portfolio && depositItem.portfolio.description">{{ depositItem.portfolio.description }}</p>
              </div>

              <div class="dashboard-deposit-item-row dashboard-deposit-item-graph-row">
                <div class="dashboard-deposit-graph-item">
                  <Bar
                    v-if="depositItem.portfolio && depositItem.portfolio.chart_interest_rates"
                    :chart-options="chartOptions"
                    :chart-data="getPortfolioChartData(depositItem)"
                    :chart-id="`deposit-portfolio-bar-${depositItem.id}`"
                    :dataset-id-key="datasetIdKey"
                    :plugins="plugins"
                    :css-classes="cssClasses"
                    :styles="styles"
                    :width="width"
                    :height="height"
                  />
                </div>

                <div v-if="depositItem.portfolio && depositItem.portfolio.chart_interest_rates" class="dashboard-deposit-item-graph-info">
                  <div
                    class="dashboard-deposit-item-graph-info-line"
                    v-for="growData in depositItem.portfolio.chart_interest_rates.columnData"
                    :key="growData.interest_rate">
                    <span class="month_formatted">
                      {{isEmptyField(growData, 'month_formatted')}}
                    </span>
                    <span
                      class="interest_rate"
                      :class="{ 'interest_rate-increase': growData.interest_rate > 0, 'interest_rate-decrease': growData.interest_rate < 0, 'interest_rate-nullable': growData.interest_rate == 0 }">
                      {{isEmptyField(growData, 'interest_rate')}}%
                    </span>
                  </div>
                </div>
              </div>

              <div class="dashboard-deposit-item-row dashboard-deposit-item-compound-row">
                <div class="dashboard-deposit-item-compound-range">
                  <div class="dashboard-deposit-item-compound-range-wrap">
                    <vue-slider
                      :adsorb="true"
                      :marks="compoundSliderMarks"
                      :min="0"
                      :max="100"
                      :tooltip-style="{ color: '#237F71', backgroundColor: 'transparent', borderColor: 'transparent', position: 'relative', top: '15px' }"
                      :process-style="{ backgroundColor: '#237F71' }"
                      :dot-style="{ border: '4px solid #237F71', borderRadius: '50%', backgroundColor: '#FFFFFF' }"
                      :step-style="{ width: '1px' }"
                      v-model="depositItem.compound_rate"
                      @change="addItemToChangedItems(depositItem.id)">
                    </vue-slider>
                  </div>

                  <div class="dashboard-deposit-item-compound-range-btn">
                    <button
                      type="button"
                      :disabled="saveButtonDisabled && saveButtonCurrentItem === depositItem.id || !changedItems.includes(depositItem.id)"
                      @click="updateClientDepositFromDashboard(depositItem.id, { compound_rate: depositItem.compound_rate })">
                      Save
                    </button>
                  </div>
                </div>

                <div class="dashboard-deposit-item-compound-title">
                  <p>Compounding %</p>
                </div>
              </div>

              <div class="dashboard-deposit-item-row dashboard-deposit-item-info-row">
                <div class="dashboard-deposit-item-current-value">
                  <span :title="getDepositCurrentValueTitleInceptionDate(depositItem)">Current Value:</span>
                  <span v-html="formatEuroCurrencySymbol(`€${depositItem.full_amount_in_currency}`)"></span>
                </div>

                <div
                  class="dashboard-deposit-item-days-left"
                  :class="{ 'dashboard-deposit-item-days-left-normal': depositItem.days_left_to_maturity > 30, 'dashboard-deposit-item-days-left-low': depositItem.days_left_to_maturity <= 30 }">
                  <span>Days Left:</span>
                  <span>{{ depositItem.days_left_to_maturity }}</span>
                </div>
              </div>

            </div>
          </div>
          <div v-else class="dashboard-deposit-list-items">
            THERE IS NO ACTIVE DEPOSITS
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import isEmptyField from "@/mixins/isEmptyField"
import currencySymbolFormatter from "@/mixins/currencySymbolFormatter"
import HelperFunctions from "@/common/helperFunctions"
import DropdownList from "@/components/basic/DropdownList"
import DepositsWidgetDropdown from "@/components/basic/dropdowns/DepositsWidgetDropdown"
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'
import {
  UPDATE_DASHBOARD_DEPOSIT
} from "@/store/actions"
import { Bar } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, BarElement, CategoryScale, LinearScale } from 'chart.js'

ChartJS.register(Title, Tooltip, BarElement, CategoryScale, LinearScale)


export default {
  name: "DepositsListWidget",
  components: {
    DropdownList,
    DepositsWidgetDropdown,
    Bar,
    VueSlider,
  },
  mixins:[
    isEmptyField,
    currencySymbolFormatter,
  ],
  props:{
    widgetData: {
      type: Object,
      default: null
    },
    datasetIdKey: {
      type: String,
      default: 'label'
    },
    width: {
      type: Number,
      default: 400
    },
    height: {
      type: Number,
      default: 400
    },
    cssClasses: {
      default: '',
      type: String
    },
    styles: {
      type: Object,
      default: () => {}
    },
    plugins: {
      type: Array,
      default: () => []
    }
  },
  data(){
    return{
      saveButtonCurrentItem: null,
      changedItems: [],
      saveButtonDisabled: false,
      compoundSliderMarks: [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          datalabels: false,
          legend: {
            display: false,
          },
        },
      },
    }
  },
  watch: {
    widgetData: function() {
      this.prepareWidgetData()
    }
  },
  mounted() {
    if (this.widgetData && this.widgetData.widget_data) {
      this.prepareWidgetData()
    }
  },
  methods: {
    addItemToChangedItems(id){
      if (!this.changedItems.includes(id)) {
        this.changedItems.push(id)
      }
    },
    prepareWidgetData(){
      let preparedWidgetData = []

      this.widgetData.widget_data.forEach((item) => {
        if (item.portfolio) {
          let chartData = HelperFunctions.preparePortfolioChartData(item.portfolio)

          item.portfolio.interest_rates_list = chartData.interest_rates_list
          item.portfolio.chart_interest_rates = chartData.chart_interest_rates
        }

        preparedWidgetData.push(item)
      })

      Object.assign(this.widgetData, { widget_data: preparedWidgetData })
    },
    getDepositInterestType(type){
      let icon = type === 'increase' ? 'grow_icon.svg' : 'decrease_icon.svg'

      return require(`@/assets/img/icons/${icon}`)
    },
    getPortfolioChartData(depositItem) {
      return depositItem.portfolio.chart_interest_rates ? {
        labels: depositItem.portfolio.chart_interest_rates.chartData.labels,
        datasets: [
          {
            // label: "Data",
            backgroundColor: depositItem.portfolio.chart_interest_rates.chartData.data.map(value => value >= 0 ? "#237f71d1" : "#DA567E"),
            data: depositItem.portfolio.chart_interest_rates.chartData.data,
            // borderWidth: 2,
            borderRadius: 8,
          },
        ],
      } : {}
    },
    convertInceptionDate(date){
      return HelperFunctions.convertDate(new Date(date))
    },
    getDepositCurrentValueTitleInceptionDate(depositItem){
      return `Initial investment: €${HelperFunctions.numberFormat(depositItem.amount_in_currency, 2)}\nCompounded Returns: €${HelperFunctions.numberFormat(depositItem.compound_amount, 2)}`
    },
    async updateClientDepositFromDashboard(id, params){
      this.saveButtonCurrentItem = id
      this.saveButtonDisabled = true
      await this.$store.dispatch(UPDATE_DASHBOARD_DEPOSIT, {
        id: id,
        params: params,
      }).then(() => {
        //
      }).finally(() => {
        this.saveButtonCurrentItem = null
        this.saveButtonDisabled = false

        let index = this.changedItems.indexOf(id)
        if (index !== -1) {
          this.changedItems.splice(index, 1)
        }
      })
    },
  },
}
</script>
