<template>
  <user-dashboard-layout :key="dashboardKey">
    <transition name="fade">
      <template v-if="checkUserDocumentsVerified()">
        <div v-if="$auth.user() && $auth.user().has_deposits" class="dashboard-widgets-container">
          <p v-if="loader" class="loader"></p>
          <DashboardWidgets ref="DashboardWidgets"/>
        </div>
        <div v-else class="dashboard-invest-container">
          <DashboardPortfolios ref="DashboardPortfolios"/>
        </div>
      </template>
      <div v-else class="scroll-container dashboard-verification-container ">
        <div class="invest-steps-container dashboard-verification-steps-container">
          <div
            class="invest-step-wrap-head dashboard-verification-step-wrap-head"
            v-for="step in stepsList"
            :key="step.name">

            <img v-if="step.checked" src="@/assets/img/icons/checked_step.svg" alt=""/>
            <img v-else src="@/assets/img/icons/not_checked_step.svg" alt=""/>

            <span>{{step.number}}</span>
            <p>{{step.name}}</p>
          </div>
        </div>

        <div class="dashboard-verification-wrap" :class="{ 'dashboard-verification-pending-wrap': checkUserDocumentsVerificationInProgress() }">
          <template v-if="checkUserDocumentsVerificationInProgress()">
            <IdentityVerificationInProgress/>
          </template>
          <template v-else>
            <div v-if="!showDocumentsVerificationInfo && !showDocumentsVerificationError">
              <IdentityVerificationForm
                @triggerDocumentsVerificationError="triggerDocumentsVerificationError"
                @triggerDocumentsVerificationInProgress="triggerDocumentsVerificationInProgress"
              />
            </div>
            <div v-else class="dashboard-verification-pending-wrap d-flex flex-column flex-1">
              <template v-if="showDocumentsVerificationInfo && !showDocumentsVerificationError">
                <IdentityVerificationInProgress/>
              </template>

              <template v-if="!showDocumentsVerificationInfo && showDocumentsVerificationError">
                <IdentityVerificationError @restartDashboardVerification="tryVerificationAgain"/>
              </template>
            </div>
          </template>
        </div>
      </div>
    </transition>
  </user-dashboard-layout>
</template>

<script>
import UserDashboardLayout from "../../layouts/UserDashboardLayout"
import checkUserVerification from "../../mixins/checkUserVerification"
import checkUserRole from "@/mixins/checkUserRole"
import redirectUnverifiedUser from "../../mixins/redirectUnverifiedUser"
import redirectUserByRole from "../../mixins/redirectUserByRole"
import HelperFunctions from "../../common/helperFunctions"
import IdentityVerificationInProgress from "../../components/dashboard/common/IdentityVerificationInProgress"
import IdentityVerificationError from "../../components/dashboard/common/IdentityVerificationError"
import IdentityVerificationForm from "../../components/dashboard/common/IdentityVerificationForm"
import DashboardPortfolios from "../../components/dashboard/common/DashboardPortfolios"
import DashboardWidgets from "../../components/dashboard/common/DashboardWidgets"
import {eventBus} from "../../main"
import {
  FETCH_DASHBOARD_WIDGETS_LIST
} from "@/store/actions"

export default {
  name: "dashboard-index",
  components: {
    UserDashboardLayout,
    IdentityVerificationError,
    IdentityVerificationInProgress,
    IdentityVerificationForm,
    DashboardPortfolios,
    DashboardWidgets,
  },
  mixins: [
    checkUserVerification,
    checkUserRole,
    redirectUnverifiedUser,
    redirectUserByRole,
  ],
  data: function () {
    return {
      stepsList: [
        { number: '01', name: 'Personal Data', checked: true },
        { number: '02', name: 'Contact Information', checked: true },
        { number: '03', name: 'Additional Information', checked: true },
        { number: '04', name: 'Identity Verification', checked: false },
      ],
      dashboardKey: HelperFunctions.uniqueId(),
      showDocumentsVerificationInfo: false,
      showDocumentsVerificationError: false,
      loader: false,
    }
  },
  created() {
    eventBus.$on('UserDocumentsVerificationPending', () => {
      // Refresh the page
      this.$router.go()
    })
    eventBus.$on('userDocumentsVerificationError', () => {
      // Refresh the page
      this.$router.go()
    })
    eventBus.$on('userDocumentsVerificationSuccess', () => {
      // Refresh the page
      this.$router.go()
    })
  },
  mounted() {
    let authUser = this.$auth.user()

    if (authUser.has_deposits) {
      this.fetchDashboardWidgetsList()
    }
  },
  methods: {
    async fetchDashboardWidgetsList() {
      this.loader = true
      await this.$store.dispatch(FETCH_DASHBOARD_WIDGETS_LIST, {
        //
      }).then(() => {
        if (this.$refs.DashboardWidgets) {
          this.$refs.DashboardWidgets.prepareChartsData()
        }
      }).finally(() => {
        this.loader = false
      })
    },
    tryVerificationAgain(){
      this.showDocumentsVerificationInfo = false
      this.showDocumentsVerificationError = false
    },
    triggerDocumentsVerificationError(){
      this.showDocumentsVerificationInfo = false
      this.showDocumentsVerificationError = true
    },
    triggerDocumentsVerificationInProgress(){
      this.showDocumentsVerificationInfo = true
      this.showDocumentsVerificationError = false
    },
  },
}
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
