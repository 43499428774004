import gmtTimezones from "@/mixins/gmtTimezones"
// import HelperFunctions from "@/common/helperFunctions"

export default {
  mixins: [
    gmtTimezones,
  ],
  methods: {
    timestampToFormattedDate (timestamp, getUTC = false, placeholder = '') {
      if (!timestamp) return placeholder
      let newDate, dateString
      let year, month, date, hours, minutes
      newDate = this.setDateTimezone(new Date(timestamp * 1000))

      if (getUTC) {
        year = newDate.getUTCFullYear()
        month = newDate.getUTCMonth() + 1
        date = newDate.getUTCDate()
        hours = newDate.getUTCHours()
        minutes = newDate.getUTCMinutes()
        // seconds = newDate.getUTCSeconds()
      } else {
        year = newDate.getFullYear()
        month = newDate.getMonth() + 1
        date = newDate.getDate()
        hours = newDate.getHours()
        minutes = newDate.getMinutes()
        // seconds = newDate.getSeconds()
      }

      // month = HelperFunctions.getMonthShortName(month)

      let amPm = hours >= 12 ? 'PM' : 'AM'
      hours = hours % 12
      hours = hours ? hours : 12 // the hour '0' should be '12'
      minutes = minutes < 10 ? '0'+minutes : minutes

      dateString =
        // ('0' + date).slice(-2) + ' ' +
        // month + ' ' +
        ('0' + date).slice(-2) + '.' +
        ('0' + month).slice(-2) + '.' +
        year + ' ' +
        hours + ':' + minutes + ' ' + amPm
      // ('0' + hours).slice(-2) + ':' +
      // ('0' + minutes).slice(-2)
      // ('0' + seconds).slice(-2) + ' - ' +

      return dateString
    },
  }
}
