<template>
  <div class="dashboard-widget-container active-investment-widget">
    <div class="active-investment-widget-head">
      <h3 v-if="widgetData && widgetData.title">{{widgetData.title}}</h3>

      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <span class="help_tip_btn" v-bind="attrs" v-on="on">?</span>
        </template>
        <span>Total amount of all assets under management</span>
      </v-tooltip>
    </div>
    <div class="active-investment-widget-content">
      <p v-if="widgetData && widgetData.widget_data" v-html="formatEuroCurrencySymbol(widgetData.widget_data.amount)"></p>
      <span v-if="widgetData.widget_data.percent > 0">+ {{widgetData.widget_data.percent}} % Increase</span>
    </div>
  </div>
</template>

<script>
import currencySymbolFormatter from "@/mixins/currencySymbolFormatter"

export default {
  name: "ActiveInvestmentWidget",
  props:['widgetData'],
  mixins:[
    currencySymbolFormatter
  ]
}
</script>
