<template>
  <div>
    <div class="d-flex flex-1 flex-column justify-space-around align-center text-center">
      <h1 class="text-center form-title mb-5">Identity Verification</h1>
    </div>

    <div class="d-flex flex-1 flex-column justify-space-around align-center text-center mb-10">
      <img
        src="@/assets/img/verify.svg"
        alt="verify"
        :width="114"
        :height="114">
      <p class="cropped-width text--body-1">
        Please get your passport or ID ready to complete secure and automated identity verification process by clicking the button below
      </p>
    </div>

    <div class="d-flex flex-1 flex-column justify-space-around align-center text-center mb-10">
      <AmlbotVerificationButton/>
    </div>

    <div class="d-flex flex-1 flex-column justify-space-around align-center text-center mb-10">
      <p class="cropped-width text--body-1">
        Or you can <span class="verification-show-form-btn" @click="toggleDocumentsForm">upload your documents</span> to complete the onboarding procedure
      </p>
    </div>

    <v-slide-y-transition>
      <div v-if="showDocumentsForm">
        <div class="d-flex flex-1 flex-column justify-space-around align-center text-center mb-10">
          <v-row class="identity-verification-row">
            <v-col cols="12" sm="4" class="identity-verification-type-col">
              <label class="form-label" for="identity_verification_type">Identity verification type*</label>
              <v-select
                id="identity_verification_type"
                v-model="payload.identity_verification_type"
                :error-messages="errors.identity_verification_type"
                :items="identity_verification_types"
                name="label"
                item-text="label"
                flat
                full-width
                label="Select..."
                :rules="[v=> !!v || 'The field is required']"
                required
                :disabled="loading"
                solo>
                <template #append>
                  <v-icon class="rounded-circle pa-2">mdi-chevron-down</v-icon>
                </template>
              </v-select>
            </v-col>
            <v-col cols="12" sm="4" class="identity-verification-proof-col">
              <label class="form-label" for="identity_verification_proof" style="visibility:hidden;">
                File
              </label>
              <v-text-field
                id="identity_verification_proof"
                v-model="payload.identity_verification_proof_name"
                :error-messages="errors.identity_verification_proof"
                :label="identity_verification_proof_name ? identity_verification_proof_name : 'Select file...'"
                :readonly="true"
                :disabled="true"
                solo/>
            </v-col>
            <v-col cols="12" sm="4" class="identity-verification-btn-col ma-auto">
              <label for="identity_verification_file" class="basic_green-btn reg-identify">
                Upload
              </label>
              <input
                @change="uploadFile('identity_verification')"
                id="identity_verification_file"
                style="visibility:hidden;width: 0;"
                type="file"
                ref="identity_verification_file"
                accept="image/jpg, image/jpeg, image/png"
                :disabled="loading">
            </v-col>
          </v-row>

          <v-row class="address-verification-row">
            <v-col cols="12" sm="4" class="address-verification-type-col">
              <label class="form-label" for="address_verification_type">Address verification type*</label>
              <v-select
                id="address_verification_type"
                v-model="payload.address_verification_type"
                :error-messages="errors.address_verification_type"
                :items="address_verification_types"
                name="label"
                item-text="label"
                flat
                label="Select..."
                :rules="[v=> !!v || 'The field is required']"
                required
                :disabled="loading"
                solo>
                <template #append>
                  <v-icon class="rounded-circle pa-2">mdi-chevron-down</v-icon>
                </template>
              </v-select>
            </v-col>
            <v-col cols="12" sm="4" class="address-verification-proof-col">
              <label class="form-label" for="address_verification_proof" style="visibility:hidden;">
                File
              </label>
              <v-text-field
                id="address_verification_proof"
                v-model="payload.address_verification_proof_name"
                :error-messages="errors.address_verification_proof"
                :label="address_verification_proof_name ? address_verification_proof_name : 'Select file...'"
                :readonly="true"
                :disabled="true"
                solo/>
            </v-col>
            <v-col cols="12" sm="4" class="address-verification-btn-col ma-auto">
              <label for="address_verification_file" class="basic_green-btn reg-identify">
                Upload
              </label>
              <input
                @change="uploadFile('address_verification')"
                id="address_verification_file"
                style="visibility:hidden;width: 0;"
                type="file"
                ref="address_verification_file"
                accept="image/jpg, image/jpeg, image/png"
                :disabled="loading">
            </v-col>
          </v-row>
        </div>

        <div class="form-controls identify-form-controls d-flex align-center justify-center text-center flex-wrap flex-md-nowrap">
          <div class="step-btn-container"></div>

          <div class="step-btn-container">
            <v-btn
              @click="handleDocumentsUpload()"
              color="primary"
              elevation="0"
              x-large
              class="step-btn">Submit
            </v-btn>
          </div>
        </div>
      </div>
    </v-slide-y-transition>
  </div>
</template>

<script>
import Api from "@/api/Api"
import AmlbotVerificationButton from "../../auth/AmlbotVerificationButton.vue"

export default {
  name: "IdentityVerificationForm",
  components: {AmlbotVerificationButton},
  data() {
    return {
      payload: {
        identity_verification_type: null,
        identity_verification_proof: null,
        address_verification_type: null,
        address_verification_proof: null,
      },
      identity_verification_proof_name: null,
      address_verification_proof_name: null,
      identity_verification_types: [
        { value: 'passport', label: 'Passport' },
        { value: 'national_id', label: 'National ID' },
        { value: 'driver_license', label: 'Driver license' },
      ],
      address_verification_types: [
        { value: 'utility_bill', label: 'Utility bill' },
        { value: 'phone_bill', label: 'Phone bill' },
        { value: 'bank_statement', label: 'Bank statement' },
      ],
      errors: {},
      loading: false,
      showDocumentsForm: false,
    }
  },
  watch: {
    'payload.identity_verification_type'() {
      this.errors.identity_verification_type = ''
    },
    'payload.identity_verification_proof'() {
      this.errors.identity_verification_proof = ''
    },
    'payload.address_verification_type'() {
      this.errors.address_verification_type = ''
    },
    'payload.address_verification_proof'() {
      this.errors.address_verification_proof = ''
    },
  },
  methods: {
    toggleDocumentsForm(){
      this.showDocumentsForm = !this.showDocumentsForm
    },
    uploadFile(type){
      let input = event.target

      // Check if file is selected
      if (input.files && input.files[0]) {
        let file = input.files[0]
        let reader = new FileReader()

        // Prepare for preview
        // let vm = this
        // reader.onload = function(e) {
        //   vm.documentPreview = e.target.result
        // }

        reader.readAsDataURL(input.files[0])

        // Prepare for request
        if (type === 'identity_verification') {
          this.identity_verification_proof_name = file.name

          this.$set(this.payload, 'identity_verification_proof', file)
        } else {
          this.address_verification_proof_name = file.name

          this.$set(this.payload, 'address_verification_proof', file)
        }
      }
    },
    async handleDocumentsUpload() {
      if (this.loading) return
      this.loading = true

      let formData = new FormData()

      formData.append('identity_verification_type', this.payload.identity_verification_type)
      formData.append('identity_verification_proof', this.payload.identity_verification_proof)
      formData.append('address_verification_type', this.payload.address_verification_type)
      formData.append('address_verification_proof', this.payload.address_verification_proof)

      try {
        await Api.uploadUserDocuments(formData)
      } catch (e) {
        this.errors = e.response?.data?.errors || {}
      } finally {
        this.loading = false
        this.triggerDocumentsVerificationInProgress()
      }
    },
    triggerDocumentsVerificationError(){
      this.$emit("triggerDocumentsVerificationError")
    },
    triggerDocumentsVerificationInProgress(){
      this.$emit("triggerDocumentsVerificationInProgress")
    },
  },
}
</script>
