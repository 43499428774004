export default {
  methods: {
    checkStatementTypeIcon(statementType) {
      switch (statementType) {
      case 'deposit':
        return require('@/assets/img/icons/statement-deposit-icon.svg')
      case 'deposit_matured':
        return require('@/assets/img/icons/statement-deposit-returns-icon.svg')
      case 'withdrawal':
        return require('@/assets/img/icons/statement-withdrawal-icon.svg')
      case 'interest_earned':
        return require('@/assets/img/icons/statement-distributions-icon.svg')
      case 'performance_fee':
        return require('@/assets/img/icons/statement-fee-icon.svg')
      default:
        return false
      }
    },
  }
}
