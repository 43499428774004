<template>
  <div class="dashboard-portfolio-block">
    <p v-if="loader" class="loader"></p>

    <div class="dashboard-portfolio-head-block">
      <p>Review available investment portfolios, analyze key facts and performance statistics, and start earning with einvestment today.</p>
      <img class="ml-9" src="@/assets/img/icons/portfolio-icon.svg" alt="portfolio-icon"/>
    </div>

    <div class="dashboard-portfolio-list-scroll scroll-container dashboard-portfolio-content-block">
      <div class="dashboard-portfolio-list">
        <div
          class="dashboard-portfolio-wrap"
          v-for="portfolioItem in portfolioList"
          :key="portfolioItem.name">

          <div class="dashboard-portfolio-wrap-name">
            <h4 :title="isEmptyField(portfolioItem, 'name')">{{isEmptyField(portfolioItem, 'name')}}</h4>
            <span class="dashboard-portfolio-inception">{{isEmptyField(portfolioItem, 'since_inception').toFixed(2)}}%</span>
          </div>

          <div class="dashboard-portfolio-wrap-info">
            <p>{{isEmptyField(portfolioItem, 'description')}}</p>
          </div>

          <div class="dashboard-portfolio-wrap-graph">
            <div class="dashboard-portfolio-graph-item">
              <Bar
                v-if="portfolioItem.chart_interest_rates"
                :chart-options="chartOptions"
                :chart-data="getPortfolioChartData(portfolioItem)"
                :chart-id="chartId"
                :dataset-id-key="datasetIdKey"
                :plugins="plugins"
                :css-classes="cssClasses"
                :styles="styles"
                :width="width"
                :height="height"
              />
            </div>

            <div class="dashboard-portfolio-wrap-graph-info">
              <div
                class="dashboard-portfolio-wrap-graph-info-line"
                v-for="growData in portfolioItem.chart_interest_rates.columnData"
                :key="growData.interest_rate">
                <span class="month_formatted">{{isEmptyField(growData, 'month_formatted')}}</span>
                <span
                  class="interest_rate"
                  :class="{ 'interest_rate-increase': growData.interest_rate > 0, 'interest_rate-decrease': growData.interest_rate < 0, 'interest_rate-nullable': growData.interest_rate == 0 }">
                  {{isEmptyField(growData, 'interest_rate')}}%
                </span>
              </div>
            </div>
          </div>

          <button @click="investInSelectedPortfolio(portfolioItem)">Invest now</button>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import {
  FETCH_PORTFOLIOS_LIST,
} from "@/store/actions"
import isEmptyField from "@/mixins/isEmptyField"
import HelperFunctions from "@/common/helperFunctions"
import { Bar } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, BarElement, CategoryScale, LinearScale } from 'chart.js'

ChartJS.register(Title, Tooltip, BarElement, CategoryScale, LinearScale)

export default {
  name: "DashboardPortfolios",
  components: {
    Bar,
  },
  mixins:[
    isEmptyField,
  ],
  props: {
    chartId: {
      type: String,
      default: "portfolio-chart",
    },
    datasetIdKey: {
      type: String,
      default: "label",
    },
    width: {
      type: Number,
      default: 400,
    },
    height: {
      type: Number,
      default: 400,
    },
    cssClasses: {
      default: "",
      type: String,
    },
    styles: {
      type: Object,
      default: () => {},
    },
    plugins: {
      type: Array,
      default: () => [],
    },
  },
  data(){
    return{
      loader: false,
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          datalabels: false,
          legend: {
            display: false,
          },
        },
      },
    }
  },
  computed: {
    ...mapGetters([
      'portfolioList',
    ])
  },
  mounted() {
    this.fetchPortfolioList()
  },
  methods:{
    async fetchPortfolioList() {
      this.loader = true
      await this.$store.dispatch(FETCH_PORTFOLIOS_LIST, {
        //
      }).then(() => {
        //
      }).finally(() => {
        this.loader = false
      })
    },
    getPortfolioChartData(portfolioItem){
      return {
        labels: portfolioItem.chart_interest_rates.chartData.labels,
        datasets: [
          {
            // label: "Data",
            backgroundColor: portfolioItem.chart_interest_rates.chartData.data.map(value => value >= 0 ? "#237f71d1" : "#DA567E"),
            data: portfolioItem.chart_interest_rates.chartData.data,
            // borderWidth: 2,
            borderRadius: 8,
          },
        ],
      }
    },
    convertInceptionDate(date){
      return HelperFunctions.convertDate(new Date(date))
    },
    investInSelectedPortfolio(portfolioItem){
      this.$router.push({ name: 'client.dashboard.invest', params: {
        portfolio: portfolioItem
      } })
    },
  }
}
</script>
