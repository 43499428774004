<template>
  <div class="dashboard-widget-container performance-widget">
    <div v-if="widgetData.title" class="performance-widget-row performance-widget-title-row">
      <h3>{{widgetData.title}}</h3>
    </div>

    <div class="performance-widget-row performance-widget-graph-row">
      <Radar
        :chart-data="chartData"
        :chart-options="chartOptions"
        :chart-id="performanceRadarKey"
        :dataset-id-key="datasetIdKey"
        :plugins="plugins"
        :css-classes="cssClasses"
        :styles="styles"
        :width="width"
        :height="height"
        ref="PerformanceRadar"
      />
    </div>

    <div class="performance-widget-row performance-widget-descr-row">
      <ul>
        <li class="income-net-worth-widget-label income-widget-label">Income</li>
        <li class="income-net-worth-widget-label net-worth-widget-label">Net Worth</li>
      </ul>
    </div>
  </div>
</template>

<script>
import HelperFunctions from "@/common/helperFunctions"
import { Radar } from 'vue-chartjs/legacy'
import { Chart as ChartJS, Title, Tooltip, Legend, PointElement, LineElement, RadialLinearScale, Filler } from 'chart.js'
import vueChartJsProps from "../../../../mixins/vueChartJsProps"

ChartJS.register(Title, Tooltip, Legend, PointElement, RadialLinearScale, LineElement, Filler)

export default {
  name: "PerformanceWidget",
  components: {
    Radar,
  },
  mixins: [
    vueChartJsProps,
  ],
  data(){
    return{
      performanceRadarKey: 'performance-radar-chart',
      chartData: {},
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          r: {
            pointLabels: {
              // display: false // Hides the labels around the radar chart
            },
            ticks: {
              display: false // Hides the labels in the middel (numbers)
            }
          }
        },
        plugins: {
          datalabels: false,
          legend: {
            display: false,
          },
          tooltip: {
            callbacks: {
              title: function() {
                // let label = context[0].label || ''
                return false
              },
              label: function(context) {
                let label = '' // context.label || ''

                if (context.formattedValue !== null) {
                  label = `€${context.formattedValue.replace('-', '')}`

                  if (!context.formattedValue === '0') {
                    label = context.formattedValue.includes('-')
                      ? `- ${label}`
                      : `+ ${label}`
                  }
                }
                return label
              }
            }
          },
        },
      },
    }
  },
  mounted() {
    if (this.widgetData && this.widgetData.widget_data) {
      this.performanceRadarKey = HelperFunctions.uniqueId()

      this.prepareChartOptions()
    }
  },
  methods: {
    prepareChartOptions(){
      let periodsData = this.widgetData.widget_data

      let periodLabels = []
      let netWorthData = []
      let incomeData = []

      Object.keys(periodsData).forEach(function(key) {
        periodLabels.push(periodsData[key]['formatted_month'])
        netWorthData.push(periodsData[key]['net_worth'])
        incomeData.push(periodsData[key]['income'] > 0 ? periodsData[key]['income'] : 0)
      })

      this.chartData = Object.assign({}, {
        labels: periodLabels,
        datasets: [
          {
            label: 'Income',
            fill: true,
            backgroundColor: 'rgba(138, 141, 147, .9)',
            borderColor: 'rgba(138, 141, 147, .9)',
            pointBackgroundColor: 'transparent',
            pointBorderColor: 'transparent',
            // pointHoverBackgroundColor: '#fff',
            // pointHoverBorderColor: 'rgba(138, 141, 147, 1)',
            data: incomeData,
          },
          {
            label: 'Net Worth',
            fill: true,
            backgroundColor: 'rgba(35, 127, 113, .9)',
            borderColor: 'rgba(35, 127, 113, .9)',
            pointBackgroundColor: 'transparent',
            pointBorderColor: 'transparent',
            // pointHoverBackgroundColor: '#fff',
            // pointHoverBorderColor: 'rgba(35, 127, 113, 1)',
            data: netWorthData,
          },
        ]
      })
    },
  },
}
</script>

<style lang="scss">
.performance-widget-title-row,
.performance-widget-graph-row{
  padding-bottom: 10px;
}
.performance-widget-descr-row{
  ul{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    li{
      position: relative;
      list-style-type: none;
      font-size: 13px;
      &:not(:nth-last-of-type(1)){
        padding-right: 25px;
      }
      &:before{
        content: '';
        position: absolute;
        top: 7px;
        left: -12px;
        display: block;
        width: 6px;
        height: 6px;
        background-color: #eeeeee;
        border-radius: 50%;
      }
      &.income-widget-label:before{
        background-color: #8A8D93;
      }
      &.net-worth-widget-label:before{
        background-color: #237F71;
      }
    }
  }
}
</style>
