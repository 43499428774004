<template>
  <div class="dashboard-widget-container net-worth-widget">
    <div v-if="widgetData.title" class="net-worth-widget-row net-worth-widget-title-row">
      <h3>{{widgetData.title}}</h3>
    </div>

    <div class="net-worth-widget-row net-worth-widget-filters-row">
      <template v-if="(periodType === 'quarterly' || periodType === 'yearly') || (chartData.datasets && chartData.datasets[0] && chartData.datasets[0].data && chartData.datasets[0].data.length > 1)">
        <div class="basic-select-wrap">
          <vueSelect
            label="label"
            :reduce="id => id.value"
            :options="graphTypes"
            v-model="graphType"
            :clearable="false"
            placeholder="Total Earning"
          ></vueSelect>
        </div>

        <div class="basic-select-wrap">
          <vueSelect
            label="label"
            :reduce="id => id.value"
            :clearable="false"
            :options="periodTypes"
            v-model="periodType"
            placeholder="Monthly"
          ></vueSelect>
        </div>
      </template>
    </div>

    <div class="net-worth-widget-row net-worth-widget-graph-row">
      <LineChartGenerator
        :chart-data="chartData"
        :chart-options="chartOptions"
        :chart-id="netWorthKey"
        :dataset-id-key="datasetIdKey"
        :plugins="plugins"
        :css-classes="cssClasses"
        :styles="styles"
        :width="width"
        :height="height"
        ref="NetWorthLine"
      />
    </div>
  </div>
</template>

<script>
import HelperFunctions from "@/common/helperFunctions"
import vueSelect from "vue-select"
import {Line as LineChartGenerator} from 'vue-chartjs/legacy'
import { Chart as ChartJS, Title, Tooltip, Legend, LineElement, LinearScale, CategoryScale, PointElement, Filler } from 'chart.js'
import vueChartJsProps from "../../../../mixins/vueChartJsProps"

ChartJS.register(Title, Tooltip, Legend, LineElement, LinearScale, CategoryScale, PointElement, Filler)

export default {
  name: "NetWorthWidget",
  components: {
    vueSelect,
    LineChartGenerator,
  },
  mixins: [
    vueChartJsProps,
  ],
  data(){
    return{
      // filter options
      graphType: 'net_worth',
      periodType: 'monthly',
      graphTypes: [
        { label: 'Net Worth', value: 'net_worth' },
        { label: 'Earnings', value: 'earnings' },
      ],
      periodTypes: [
        { label: 'Monthly', value: 'monthly' },
        { label: 'Quarterly', value: 'quarterly' },
        { label: 'Yearly', value: 'yearly' },
      ],
      // chart options
      netWorthKey: 'net-worth-line-chart',
      chartData: {},
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        // bezierCurve: true,
        scales: {
          x: {
            grid: {
              display: false,
              // color: "rgba(0, 0, 0, 0)",
            }
          },
          y: {
            grid: {
              display: false,
              // color: "rgba(0, 0, 0, 0)",
            }
          },
        },
        plugins: {
          datalabels: false,
          legend: {
            display: false,
          },
          tooltip: {
            callbacks: {
              title: function(context) {
                let label = context[0].label || ''

                label += label === '1'
                  ? ' month'
                  : ' months'

                return label
              },
              label: function(context) {
                let label = '' // context.label || ''

                if (context.formattedValue !== null) {
                  label = `€${context.formattedValue.replace('-', '')}`

                  if (!context.formattedValue === '0') {
                    label = context.formattedValue.includes('-')
                      ? `- ${label}`
                      : `+ ${label}`
                  }
                }
                return label
              }
            }
          },
        },
      },
    }
  },
  watch: {
    graphType() {
      this.prepareChartOptions()
    },
    periodType() {
      this.prepareChartOptions()
    },
  },
  mounted() {
    if (this.widgetData && this.widgetData.widget_data) {
      this.netWorthKey = HelperFunctions.uniqueId()

      this.prepareChartOptions()
    }
  },
  methods: {
    prepareChartOptions(){
      let periodsData = this.widgetData.widget_data

      let periodLabels = []
      let netWorthData = []
      let earningsData = []

      Object.keys(periodsData).forEach(function(key) {
        periodLabels.push(periodsData[key]['formatted_month_day'])
        netWorthData.push(periodsData[key]['net_worth'])
        earningsData.push(periodsData[key]['earnings'] > 0 ? periodsData[key]['earnings'] : 0)
      })

      if (this.periodType === 'quarterly' || this.periodType === 'yearly') {
        let chunkSize = this.periodType === 'quarterly' ? 3 : 12

        // periodLabels = Array.apply(null, Array(chunkSize)).map(function (x, i) { return i + 1 })
        periodLabels = HelperFunctions.objChunks(periodLabels, chunkSize).map((chunk) => {
          return Object.values(chunk).slice(-1).pop()
        })


        netWorthData = HelperFunctions.objChunks(netWorthData, chunkSize).map((chunk) => {
          return Object.values(chunk).slice(-1).pop()
        })

        earningsData = HelperFunctions.objChunks(earningsData, chunkSize).map((chunk) => {
          return Object.values(chunk).slice(-1).pop()
        })
      }

      if (earningsData <= 1) { // (this.periodType !== 'quarterly' && this.periodType !== 'yearly')
        this.chartOptions.scales.x.display = false
        this.chartOptions.scales.y.display = false
      } else {
        this.chartOptions.scales.x.display = true
        this.chartOptions.scales.y.display = true
      }

      this.chartData = Object.assign({}, {
        labels: periodLabels,
        datasets: [
          {
            lineTension: .3,
            fill: true,
            backgroundColor: 'rgba(35, 127, 113, .05)', // #EFF6F5
            borderWidth: 3,
            borderColor: 'rgba(35, 127, 113, 1)',
            pointBackgroundColor: 'transparent',
            pointBorderColor: 'transparent',
            pointHoverBackgroundColor: 'rgba(255, 255, 255, 1)',
            pointHoverBorderColor: 'rgba(35, 127, 113, 1)',
            pointRadius: 7,
            pointBorderWidth: 4,
            pointHoverRadius: 7,
            pointHoverBorderWidth: 4,
            data: this.graphType === 'net_worth'
              ? netWorthData
              : earningsData,
          }
        ]
      })
    },
  },
}
</script>
