<template>
  <div class="diagram-widget">
    <div class="diagram-widget-content">
      <div class="diagram-widget-col diagram-widget-list-col">
        <template v-if="!loader && depositsListData">
          <ul class="diagram-widget-deposits-list">
            <li v-for="deposit in depositsListData" :key="deposit.label" :class="`diagram-widget-deposits-list-item`">
              <button type="button" :class="`diagram-widget-deposits-list-${deposit.color.replace('#', '')}-button`" @click="changeSelectedWidgetDeposit(deposit)">
                <span></span>
                <span><i>{{ deposit.label }}</i></span>
                <span>{{ `${deposit.value}%` }}</span>
              </button>
            </li>
          </ul>
        </template>
      </div>
      <div class="diagram-widget-col diagram-widget-doughnut-col">
        <div v-if="!loader && selectedDeposit" class="diagram-widget-selected-deposit">
          <span v-if="selectedDeposit.label" class="diagram-widget-selected-deposit-label">
            {{ selectedDeposit.label }}
          </span>
          <span v-if="selectedDeposit.value" :class="`diagram-widget-selected-deposit-value diagram-widget-selected-deposit-${selectedDeposit.color.replace('#', '')}-value`">
            {{ selectedDeposit.value }}
          </span>
        </div>

        <template v-if="!loader && chartData">
          <Doughnut
            :chart-data="chartData"
            :chart-options="chartOptions"
            :chart-id="chartId"
            :dataset-id-key="datasetIdKey"
            :plugins="plugins"
            :css-classes="cssClasses"
            :styles="styles"
            :width="width"
            :height="height"
            ref="Doughnut"
          />
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import HelperFunctions from "../../../../common/helperFunctions"
import {Doughnut} from "vue-chartjs/legacy"

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  CategoryScale
} from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale)

export default {
  name: "DepositsDoughnutWidget",
  components: {
    Doughnut,
  },
  props: {
    loader: {
      type: Boolean,
      default: false
    },
    depositsData: {
      type: Object,
      default: null
    },
    datasetIdKey: {
      type: String,
      default: 'label'
    },
    width: {
      type: Number,
      default: 157
    },
    height: {
      type: Number,
      default: 157
    },
    cssClasses: {
      default: '',
      type: String
    },
    styles: {
      type: Object,
      default: () => {}
    },
    plugins: {
      type: Array,
      default: () => [{
        id: 'customAfterDraw',
        afterDatasetDraw: function(chart, args, plugins) {
          const { ctx, data, chartArea: { top, bottom, left, right, width, height } } = chart

          let hasData = false

          for (let i = 0; i < data.datasets.length; i++) {
            const obj = data.datasets[i]

            hasData = obj.data.every(function(element) {
              return element !== 0
            })
          }
          if (!hasData) {
            ctx.fillStyle = 'rgba(255, 255, 255, 1)'
            ctx.fillRect(left, top, width, height)

            ctx.font = 'bold 20px sans-serif'
            ctx.fillStyle = '#C7C6CE'
            ctx.textAlign = 'center'
            ctx.fillText('No data', left + width / 2, top + height / 1.7)
          }
        }
      }]
    }
  },
  data(){
    return{
      // Common
      colorPalette: ['#237F71', '#FF8450', '#4F6EC5', '#EE9D9A'],
      // Widgets data
      selectedDeposit: {
        label: null,
        data: null,
      },
      depositsListData: [],
      chartId: null,
      chartData: {},
      // https://www.chartjs.org/docs/latest/charts/doughnut.html
      // https://vue-chartjs.org/examples/#vue-3-charts
      // https://codesandbox.io/s/lively-cloud-eobqlx?file=/src/components/Doughnut.vue:1433-1456
      // https://stackoverflow.com/a/54080553/14272842
      // https://www.youtube.com/watch?v=8QvdasvZgVg
      chartOptions: {
        borderWidth: 1,
        cutout: "75%",
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          datalabels: false,
          legend: {
            display: false,
          },
          tooltip: {
            callbacks: {
              label: function(context) {
                let label = context.label || ''

                if (label) {
                  label += ': '
                }

                if (context.formattedValue !== null) {
                  label += `${context.formattedValue}%`
                }
                return label
              }
            }
          }
        },
      }
    }
  },
  mounted() {
    this.chartId = HelperFunctions.uniqueId()
  },
  methods: {
    /*
     * ChartJs Widget Methods
     */
    prepareChartData(){
      if (this.depositsData) {
        let depositsPieWidget = this.depositsData
        let colorPalette = this.colorPalette

        let firstDepositKey = Object.keys(this.depositsData)[0]
        let depositsListData = []
        let depositsChartData = {
          labels: [],
          datasets: [
            {
              backgroundColor: [],
              data: [],
            }
          ]
        }

        // Prepare selected Deposit
        this.selectedDeposit = {
          color: firstDepositKey === 'Free balance' ? '#EBEBEB' : '#237F71',
          label: firstDepositKey,
          value: `${this.depositsData[firstDepositKey]}%`,
        }

        // Prepare Deposits list data
        HelperFunctions.objChunks(depositsPieWidget, 4).forEach(function(chunk) {
          Object.keys(chunk).forEach(function(key, index) {
            let itemColor = key === 'Free balance'
              ? '#EBEBEB'
              : colorPalette[index]

            depositsListData.push({
              color: itemColor,
              label: key,
              value: depositsPieWidget[key],
            })
          })
        })

        // Prepare Deposits Widget data
        depositsListData.forEach(function(item) {
          depositsChartData.labels.push(item.label)
          depositsChartData.datasets[0].backgroundColor.push(item.color)
          depositsChartData.datasets[0].data.push(item.value)
        })

        // Save data to local data
        this.depositsListData = depositsListData
        this.chartData = Object.assign({}, depositsChartData)
      }
    },
    changeSelectedWidgetDeposit(deposit){
      this.selectedDeposit = {
        color: deposit.color,
        label: deposit.label,
        value: `${deposit.value}%`,
      }
    },
  },
}
</script>

<style scoped>

</style>
