<template>
  <div class="dashboard-widget-container earnings-expenses-widget">
    <div v-if="widgetData.title" class="earnings-expenses-widget-row earnings-expenses-widget-title-row">
      <h3>{{widgetData.title}}</h3>
    </div>

    <div class="earnings-expenses-widget-row earnings-expenses-widget-graph-1-row">
      <Bar
        :chart-data="earningsChartData"
        :chart-options="earningsChartOptions"
        :chart-id="earningsBarKey"
        :dataset-id-key="datasetIdKey"
        :plugins="plugins"
        :css-classes="cssClasses"
        :styles="styles"
        :width="width"
        :height="height"
        ref="EarningsBar"
      />
    </div>

    <div class="earnings-expenses-widget-row earnings-expenses-widget-graph-2-row">
      <Bar
        :chart-data="expensesChartData"
        :chart-options="expensesChartOptions"
        :chart-id="expensesBarKey"
        :dataset-id-key="datasetIdKey"
        :plugins="plugins"
        :css-classes="cssClasses"
        :styles="styles"
        :width="width"
        :height="height"
        ref="ExpensesBar"
      />
    </div>

    <div class="earnings-expenses-widget-row earnings-expenses-widget-descr-row">
      <ul>
        <li class="earnings-expenses-widget-label earnings-widget-label">Earning</li>
        <li class="earnings-expenses-widget-label expenses-widget-label">Expense</li>
      </ul>
    </div>
  </div>
</template>

<script>
import HelperFunctions from "@/common/helperFunctions"
import { Bar } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, BarElement, CategoryScale, LinearScale } from 'chart.js'

ChartJS.register(Title, Tooltip, BarElement, CategoryScale, LinearScale)

export default {
  name: "EarningsExpensesWidget",
  components: {
    Bar,
  },
  props: {
    widgetData: {
      type: Object,
      default: null
    },
    datasetIdKey: {
      type: String,
      default: "label",
    },
    width: {
      type: Number,
      default: 400,
    },
    height: {
      type: Number,
      default: 400,
    },
    cssClasses: {
      default: "",
      type: String,
    },
    styles: {
      type: Object,
      default: () => {},
    },
    plugins: {
      type: Array,
      default: () => [{
        id: 'customAfterDraw',
        afterDatasetDraw: function(chart, args, plugins) {
          const { ctx, data, chartArea: { top, bottom, left, right, width, height } } = chart

          let hasData = false

          for (let i = 0; i < data.datasets.length; i++) {
            const obj = data.datasets[i]

            hasData = obj.data.every(function(element) {
              return element !== 0
            })
          }

          if (!hasData) {
            ctx.fillStyle = 'rgba(255, 255, 255, 1)'
            ctx.fillRect(left, top, width, height)

            ctx.font = 'bold 20px sans-serif'
            ctx.fillStyle = '#C7C6CE'
            ctx.textAlign = 'center'
            ctx.fillText('No data', left + width / 2, top + height / 2)
          }
        }
      }],
    },
  },
  data(){
    return{
      earningsBarKey: 'earnings-line-chart',
      expensesBarKey: 'expenses-line-chart',
      earningsChartData: {},
      expensesChartData: {},
      earningsChartOptions: {},
      expensesChartOptions: {},
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            display: false,
          },
          y: {
            display: false,
            beginAtZero: true,
          }
        },
        plugins: {
          datalabels: false,
          legend: {
            display: false,
          },
          tooltip: {
            callbacks: {
              label: function(context) {
                let label = '' // context.label || ''

                // if (label) {
                //   label += ': '
                // }

                if (context.formattedValue !== null) {
                  label = `€${context.formattedValue.replace('-', '')}`

                  if (!context.formattedValue === '0') {
                    label = context.formattedValue.includes('-')
                      ? `- ${label}`
                      : `+ ${label}`
                  }
                }
                return label
              }
            }
          },
        },
      },
    }
  },
  mounted() {
    if (this.widgetData && this.widgetData.widget_data) {
      this.earningsBarKey = HelperFunctions.uniqueId()
      this.expensesBarKey = HelperFunctions.uniqueId()

      this.prepareChartOptions()
    }
  },
  methods: {
    prepareChartOptions(){
      let periodsData = this.widgetData.widget_data

      let commonData = {
        barThickness: 10,
        borderRadius: 32,
        borderSkipped: false,
      }

      let periodLabels = []
      let earningsData = []
      let expensesData = []

      Object.keys(periodsData).forEach(function(key) {
        periodLabels.push(key)
        earningsData.push(periodsData[key]['earnings'])
        expensesData.push(periodsData[key]['expenses'] > 0 ? -Math.abs(periodsData[key]['expenses']) : periodsData[key]['expenses'])
      })

      /*
       * Set options
       */
      this.earningsChartOptions = JSON.parse(JSON.stringify(this.chartOptions))
      this.expensesChartOptions = JSON.parse(JSON.stringify(this.chartOptions))

      let maxEarningsValue = earningsData.length > 0 ? Math.max.apply(Math, earningsData) : 0

      if (maxEarningsValue) {
        this.expensesChartOptions.scales.y.min = -1 * Math.abs(maxEarningsValue)
      }

      /*
       * Set data
       */
      this.earningsChartData = Object.assign({}, {
        labels: periodLabels,
        datasets: [
          Object.assign({}, commonData, {
            backgroundColor: "#237F71",
            data: earningsData
          })
        ]
      })

      this.expensesChartData = Object.assign({}, {
        labels: periodLabels,
        datasets: [
          Object.assign({}, commonData, {
            backgroundColor: "#8A8D93",
            data: expensesData
          })
        ]
      })
    },
  },
}
</script>
