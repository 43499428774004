<template>
  <div class="dashboard-widget-block">
    <div class="dashboard-widget-list-scroll scroll-container">

      <div class="dashboard-widget-list">

        <div v-if="!loader && dashboardWidgetsList" class="dashboard-widget-list-1_line">
          <div class="dashboard-widget-list-left-side">
            <AvailableFundsWidget
              v-if="dashboardWidgetsList.available_funds"
              :loader="loader"
              :widgetData="dashboardWidgetsList.available_funds"
              ref="AvailableFundsDesktop"/>

            <ActiveInvestmentWidget
              v-if="dashboardWidgetsList.active_investments"
              :loader="loader"
              :widgetData="dashboardWidgetsList.active_investments"
              ref="ActiveInvestmentDesktop"/>

            <CapitalRecaptureWidget
              v-if="dashboardWidgetsList.capital_recapture"
              :loader="loader"
              :widgetData="dashboardWidgetsList.capital_recapture"
              ref="CapitalRecaptureDesktop"/>
          </div>

          <div class="dashboard-widget-list-right-side">
            <InfoBannerWidget
              v-if="dashboardWidgetsList.info_banner"
              :loader="loader"
              :widgetData="dashboardWidgetsList.info_banner"
              ref="InfoBannerDesktop"/>
          </div>
        </div>


        <div v-if="!loader && dashboardWidgetsList" class="dashboard-widget-list-2_line">
          <div class="dashboard-widget-list-left-side">
           <NetWorthWidget
             v-if="dashboardWidgetsList.net_worth"
             :loader="loader"
             :widgetData="dashboardWidgetsList.net_worth"/>
          </div>

          <div class="dashboard-widget-list-right-side">
            <div v-if="dashboardWidgetsList && dashboardWidgetsList.asset_allocation" class="dashboard-widget-container doughnut-widget">
              <h3>Return Statistics</h3>
              <DepositsDoughnutWidget
                :loader="loader"
                :depositsData="dashboardWidgetsList.asset_allocation.widget_data"
                ref="DepositsDoughnutWidgetDesktop"/>
            </div>

            <PerformanceWidget
              v-if="dashboardWidgetsList.performance"
              :loader="loader"
              :widgetData="dashboardWidgetsList.performance"/>
          </div>
        </div>


        <div v-if="!loader && dashboardWidgetsList" class="dashboard-widget-list-3_line">
          <div class="dashboard-widget-list-left-side">
            <div class="dashboard-widget-container deposits_list-widget">
              <DepositsListWidget
                v-if="dashboardWidgetsList.deposits_list"
                :loader="loader"
                :widgetData="dashboardWidgetsList.deposits_list"/>
            </div>
          </div>
          <div class="dashboard-widget-list-right-side">
            <div class="dashboard-widget-container invest_more-widget">
              <InvestMoreWidget/>
            </div>
            <div class="dashboard-widget-container earnings_expenses-widget">
              <EarningsExpensesWidget
                v-if="dashboardWidgetsList.earnings_expenses"
                :loader="loader"
                :widgetData="dashboardWidgetsList.earnings_expenses"/>
            </div>
          </div>

        </div>


        <div class="dashboard-widget-list-4_line">
          <div class="dashboard-widget-list-left-side">
            <div class="dashboard-widget-container transaction_history_and_upcoming-widget">
              <TransactionHistoryAndUpcomingWidget
                v-if="dashboardWidgetsList.transaction_history_and_upcoming"
                :loader="loader"
                :widgetData="dashboardWidgetsList.transaction_history_and_upcoming"/>
            </div>
          </div>
          <div class="dashboard-widget-list-right-side">
            <div v-if="showSubsForm && !hideSubsForm" class="dashboard-widget-container subscribe_to_newsletter_banner-widget">
              <SubscribeBannerWidget
                @closeSubsForm="showSubsForm = false"
                :loader="loader"
                :widgetData="dashboardWidgetsList.subscribe_to_newsletter_banner"/>
            </div>
            <div v-else class="dashboard-widget-container twitter-widget">
              <TwitterTimelineWidget
                :loader="loader"/>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import isEmptyField from "@/mixins/isEmptyField"
import AvailableFundsWidget from "@/components/dashboard/common/widgets/AvailableFundsWidget"
import ActiveInvestmentWidget from "@/components/dashboard/common/widgets/ActiveInvestmentWidget"
import CapitalRecaptureWidget from "@/components/dashboard/common/widgets/CapitalRecaptureWidget"
import InfoBannerWidget from "@/components/dashboard/common/widgets/InfoBannerWidget"
import DepositsDoughnutWidget from "@/components/dashboard/common/widgets/DepositsDoughnutWidget"
import NetWorthWidget from "@/components/dashboard/common/widgets/NetWorthWidget"
import PerformanceWidget from "@/components/dashboard/common/widgets/PerformanceWidget"
import EarningsExpensesWidget from "@/components/dashboard/common/widgets/EarningsExpensesWidget"
import TransactionHistoryAndUpcomingWidget from "@/components/dashboard/common/widgets/TransactionHistoryAndUpcomingWidget"
import SubscribeBannerWidget from "@/components/dashboard/common/widgets/SubscribeBannerWidget"
import DepositsListWidget from "@/components/dashboard/common/widgets/DepositsListWidget"
import TwitterTimelineWidget from "@/components/dashboard/common/widgets/TwitterTimelineWidget"
import InvestMoreWidget from "@/components/dashboard/common/widgets/InvestMoreWidget"

export default {
  name: "DashboardWidgets",
  components: {
    InvestMoreWidget,
    TwitterTimelineWidget,
    DepositsListWidget,
    SubscribeBannerWidget,
    TransactionHistoryAndUpcomingWidget,
    EarningsExpensesWidget,
    PerformanceWidget,
    NetWorthWidget,
    DepositsDoughnutWidget,
    InfoBannerWidget,
    CapitalRecaptureWidget,
    ActiveInvestmentWidget,
    AvailableFundsWidget,
  },
  mixins: [
    isEmptyField,
  ],
  props: {
    //
  },
  data() {
    return {
      loader: false,
      showSubsForm: true,
    }
  },
  computed: {
    ...mapGetters([
      'dashboardWidgetsList',
    ]),
    hideSubsForm() {
      return this.dashboardWidgetsList?.subscribe_to_newsletter_banner?.options?.hide
    }
  },
  mounted() {
    //
  },
  methods: {
    prepareChartsData(){
      this.$refs.DepositsDoughnutWidgetDesktop.prepareChartData()
      // this.$refs.DepositsDoughnutMobile.prepareChartData()
    },
  }
}
</script>
