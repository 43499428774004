<template>
  <div class="dashboard-widget-container capital-recapture-widget">
    <div class="capital-recapture-widget-head">
      <h3>{{widgetData.title}}</h3>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <span class="help_tip_btn" v-bind="attrs" v-on="on">?</span>
        </template>
        <span>Rate of return from the total amount of investments</span>
      </v-tooltip>
    </div>
    <div class="capital-recapture-widget-content">
      <p>{{checkWidgetDataPercent()}}%</p>

      <div class="capital-recapture-widget-progress-line-wrap">
        <div :style="{ width: `${widgetData.widget_data.percent}%` }" class="capital-recapture-widget-progress-line-completed"></div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "CapitalRecaptureWidget",
  props:['widgetData'],
  methods:{
    checkWidgetDataPercent(){
      return (this.widgetData.widget_data.percent > 100) ? 100 : this.widgetData.widget_data.percent
    }
  }
}
</script>
