<template>
  <div class="deposits-list-widget-col deposits-list-widget-button-col">
    <div class="deposits-list-widget-button-wrap">
      <router-link :to="{ name: 'client.dashboard.invest' }">
        <img src="@/assets/img/icons/invest-more-icon.svg" alt="Invest More"/>
        <span>Invest More</span>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "InvestMoreWidget"
}
</script>
